import React from "react";
import { Container } from "react-bootstrap";
import Intro from "./Intro";
import Final from "./Final";
import PostList from "./PostList";
import styles from "./Blog.css";

const Blog = ({ posts }) => {
  return (
    <Container className={styles.self}>
      <Intro />
      <PostList posts={posts} />
      <Final />
    </Container>
  );
};

export default Blog;
