import React from "react";
import Layout from "../../components/Layout";
import Blog from "../../components/Blog";
import { graphql } from "gatsby";
import MyHelmet from "../../context/MyHelmet";
export default ({
  data: {
    allWpPost: { nodes }
  }
}) => {
  return (
    <div>
      <MyHelmet page="blog" language="de" />
      <Layout>
        <Blog posts={nodes} />
      </Layout>
    </div>
  );
};
export const query = graphql`
  {
    allWpPost(filter: { language: { code: { eq: EN } } }) {
      nodes {
        date(locale: "")
        id
        uri
        title
        featuredImage {
          node {
            altText
            description
            sourceUrl
          }
        }
        content
        seo {
          metaDesc
          title
        }
      }
    }
  }
`;
