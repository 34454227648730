import React from "react";
import styles from "./ButtonPrimary.module.css";
import { Link } from "gatsby";

const FirstButton = props => {
  return (
    <Link to={props.to} className={styles.button}>
      {props.children}
    </Link>
  );
};

export { FirstButton };
