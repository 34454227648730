import React from "react";
import { Col } from "react-bootstrap";
import styles from "./OtherPostFields.module.css";
import { Link } from "gatsby";
import { LinkButton } from "../../../Buttons/LinkButton/LinkButton";
import { GlobalStateContext } from "../../../../context/GlobalContextProvider";
import data from "../../blogData";
import moment from "moment";

const OtherPostFields = ({ title, date, mainPic, path }) => {
  const { language } = React.useContext(GlobalStateContext);
  const { readMore } = data[language];
  return (
    <Col md={6} className={styles.self}>
      <Link to={path}>
        <div
          className={styles.image}
          style={{ backgroundImage: `url(${mainPic})` }}
        />
      </Link>
      <div className={styles.text}>
        <i>{moment(date).format("LL")}</i>
        <p>{title}</p>
        <LinkButton to={path}>{readMore}</LinkButton>
      </div>
    </Col>
  );
};

export default OtherPostFields;
