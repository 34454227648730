import React from "react";
import FirstPostField from "../FirstPostField";
import OtherPostFields from "../OtherPostFields";

const SinglePost = ({ post, isFirst, onClick }) => {
  const { title, date, featuredImage, uri } = post;
  if (isFirst) {
    return (
      <FirstPostField
        title={title}
        mainPic={featuredImage.node.sourceUrl}
        path={uri}
        onClick={onClick}
      />
    );
  }
  return (
    <OtherPostFields
      title={title}
      date={date}
      mainPic={featuredImage.node.sourceUrl}
      path={uri}
      onClick={onClick}
    />
  );
};

export default SinglePost;
