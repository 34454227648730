import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { FirstButton } from "../../../Buttons/ButtonPrimary/FirstButton";
import styles from "./FirstPostField.module.css";
import { Link } from "gatsby";
import { GlobalStateContext } from "../../../../context/GlobalContextProvider";
import data from "../../blogData";

const FirstPostField = ({ title, mainPic, path }) => {
  const { language } = React.useContext(GlobalStateContext);
  const { readMore, latestArticle } = data[language];

  return (
    <Row className={styles.self}>
      <Col md={7}>
        <Link to={path}>
          <Image fluid src={mainPic} />
        </Link>
      </Col>
      <Col md={5}>
        <p>{latestArticle}</p>
        <h2>{title}</h2>
        <FirstButton to={path}>{readMore}</FirstButton>
      </Col>
    </Row>
  );
};

export default FirstPostField;
