import React, { useContext } from "react";
import styles from "./Intro.module.css";
import data from "../blogData";
import { GlobalStateContext } from "../../../context/GlobalContextProvider";
import { Col, Row } from "react-bootstrap";

const Intro = () => {
  const { language } = useContext(GlobalStateContext);
  const { title, text } = data[language].intro;

  return (
    <Row className="justify-content-md-center">
      <Col md={6} className={styles.self}>
        <h1 className={"triangle tsmall yellow"}>{title}</h1>
        <p>{text}</p>
      </Col>
    </Row>
  );
};

export default Intro;
