import React from "react";
import styles from "./PostList.module.css";
import SinglePost from "./SinglePost";
import { Row } from "react-bootstrap";
import ArticleSchema from "../../../context/ArticleShema";

const PostList = ({ posts }) => {
  return (
    <Row className={styles.self}>
      {posts.map((post, i) => (
        <SinglePost key={post.id} post={post} isFirst={i === 0} />
      ))}
    </Row>
  );
};

export default PostList;
