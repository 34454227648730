import React, { useContext } from "react";
import styles from "./Final.module.css";
import data from "../blogData";
import { GlobalStateContext } from "../../../context/GlobalContextProvider";
import { Col } from "react-bootstrap";
import { ButtonPrimary } from "../../Buttons";
import { graphql, useStaticQuery } from "gatsby";

const Final = () => {
  const { language } = useContext(GlobalStateContext);
  const { title, button } = data[language].final;
  const { file } = useStaticQuery(
    graphql`
      query {
        file(name: { eq: "blog_footer_image" }) {
          name
          publicURL
        }
      }
    `
  );
  return (
    <div
      className={styles.self}
      style={{ backgroundImage: `url(${file.publicURL})` }}
    >
      <Col className={styles.content} md={{ span: 6, offset: 3 }}>
        <h2>{title}</h2>
        <ButtonPrimary
          variant="outline-primary"
          to="case-studies"
          language={language}
        >
          {button}
        </ButtonPrimary>
      </Col>
    </div>
  );
};

export default Final;
